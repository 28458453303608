
@import "./mixins.scss";

* {
    box-sizing: border-box;
}

.calendly-badge-widget {
    opacity: 0 !important;
    pointer-events: none !important;
}

body{
    padding: 0;
    margin: 0;
    font-size: 24px; // 1rem
}
.mt-10{
    padding-top: 10px !important;
}
.mt-20{
    padding-top: 20px !important;
}
.mt-30{
    padding-top: 30px !important;
}
.header{
    position: fixed;
    top: 20px;
    right: 20px;
    left: 20px;
    width: calc(100% - 40px);
    background-color: #fff;
    z-index: 100;
    height: 60px;
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0px 4px 6px 0px rgba(0,0,0,0.25);
    @include media ("<=tablet") {
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        border-radius: 0px;
        overflow: visible;
    }
}
.main-nav{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 16px;
    position: relative;
}
.main-nav__link{
    color: inherit;
    text-decoration: none;
}
.main-nav__logo{
    display: flex;
    align-items: center;
    &--img{
        width: 29px;
        height: 32px;
    }
    &--text{
        font-family: 'Kumbh Sans', sans-serif;
        font-size: 20px;
        line-height: 1.2;
        font-style: normal;
        font-weight: bold;
        text-align: left;
        color: #1F155B;
        margin: 0px 0px 0px 12px;
    }
}
.main-nav__menu{
    width: calc( 100% - 350px);
    display: flex;
    flex-direction: row;
    margin-right: 170px;
    align-items: center;
    max-width: 850px;
    @include media ("<=tablet"){ 
        display: none;
        background-color: #fff;
        height: calc(100% - 60px);
        top: 60px;
        left: 0;
        right: 0;
        width: 100%;
        z-index: 10;
        max-width: unset;
        flex-direction: column;
        justify-content: space-between;
        position: fixed;
    }
    &--open{
        display: flex;
    }
}
.main-nav__list{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
    // padding-bottom: 25px;
    @include media ("<=tablet"){ 
        margin: 0;
        height: 70%;
        flex-direction: column;
        justify-content: space-around;
        margin-top: 10%;
    }

}
.main-nav__list-item{
    font-family: 'Kumbh Sans', sans-serif;
    font-size: 16px;
    line-height: 1.2;
    font-style: normal;
    font-weight: bold;
    text-align: left;
    color: #1F155B;
    cursor: pointer;
    display: inline;
    transition: color 0.3s ease;
    margin-right: 20px;
    &:hover{
        color: #4730D2;
    }
    a{
        color: inherit;
        text-decoration: none;
    }
    @include media ("<=tablet"){
        display: block;
        font-size: 32px;
        text-align: center;
        margin-right: 0;
    }
    &.active {
        color: #4730D2;
    }
}
.main-nav__list-sub{
    display: flex;
    flex-direction: row;
    align-items: center;
    a{
        font-family: 'Kumbh Sans', sans-serif;
        font-size: 16px;
        line-height: 1.2;
        font-style: normal;
        font-weight: bold;
        text-align: left;
        color: #1F155B;
        cursor: pointer;
        display: inline;
        text-align: center;
        margin-right: 20px;
        transition: color 0.3s ease;
        text-decoration: none;
        &:hover{
            color: #4730D2;
        }
        @include media ("<=tablet"){
            display: block;
            font-size: 24px;
            text-align: center;
            margin-right: 0;
        }
    }
    &.active {
        a{ color: #4730D2; }
    }

}
.main-nav__demo-btn{
    position: absolute;
    right: 0;
    top: 0;
    left: auto;
    display: block;
    height: 60px;
    font-family: 'Kumbh Sans', sans-serif;
    line-height: 1.1;
    font-style: normal;
    font-weight: bold;
    text-align: left;
    color: #fff;
    text-decoration: none;
    align-self: flex-end;
    // padding: 0px 24px 0px 24px;
    // background-color: #47CE7D;
    @include media ("<=tablet"){
        text-align: center;
        position: relative;
        // height: 75px;
        width: 100%;
        font-size: 32px;
    }
    &--undefined{
        font-size: 20px;
        background-color: #4730D2;
        padding: 21px 24px 19px 24px;
        display: block;
        height: 60px;
        color: inherit;
        text-decoration: none;
        cursor: pointer;
    }
    &--defined{
        background-color: #15B743;
        font-size: 17px;
        padding: 23px 24px 19px 24px;
        display: block;
        height: 60px;
        pointer-events: none;
        color: #fff;
        text-decoration: none;
        text-align: center;
        cursor: unset;
    }
}

// Menu burger
.main-nav__burger {
    display: none;
    position: absolute;
    top: 50%;
    right: 0px;
    width: 60px;
    height: 60px;
    transform: translate(0, -50%);
    transition: 0.5s ease-in-out;
    cursor: pointer;
    z-index: 1500;
    background-color: #4730D2;
    @include media ("<=tablet") {
        display: block;
    }
    > span {
      display: block;
      position: absolute;
      height: 2px;
      width: 40px;
      background: #fff;
      border-radius: 2px;
      opacity: 1;
      left: 10px;
      top: 0;
      transform: rotate(0deg);
      transition: 0.25s ease-in-out;

      &:nth-child(1) {
        top: 19px;
      }

      &:nth-child(2) {
        top: 29px;
      }

      &:nth-child(3) {
        top: 39px;
      }
    }

    &.open {
        background-color: #fff;
        > span {
            background-color: #4730D2;
            &:nth-child(1) {
                top: 30px;
                transform: rotate(135deg);
            }

            &:nth-child(2) {
                opacity: 0;
                right: -100%;
            }

            &:nth-child(3) {
                top: 30px;
                transform: rotate(-135deg);
            }
        }
    }
}



.page{
    width: 100%;
}

.hero{
    width: 100%;
    height: calc(100vh + 20px);
    position: relative;
    max-height: 845px;
    background-image: url('/assets/img/hero-bg.jpg');
    background-position: center right;
    background-size: cover;
    background-repeat: no-repeat;
    @include media ("<=tablet"){
        max-height: unset;
     }
    @include media ("<=smallTablet"){
        background-position: 80% 0;
    }
}
.overlay-black{
    display: none;
    @include media ("<phone"){
       background-color: rgba(#000, 0.25);
       position: absolute;
       top: 0;
       left: 0;
       right: 0;
       width: 100%;
       height: 100%;
       display: block;
    }
}
.hero__title{
    width: 80%;
    max-width: 760px;
    height: auto;
    margin: 0 auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 57%;
    transform: translate(0, -50%);
    @include media ("<phone"){
        left: 20px;
        right: 20px;
        width: calc(75% - 40px);
    }
}
.defined-text{
    color: #15B743;
    font-weight: bold;
}
.hero__title-text{
    font-family: 'Kumbh Sans', sans-serif;
    line-height: 1.17;
    font-style: normal;
    text-align: center;
    color: #fff;
    margin-top: 0;
    width: 100%;
    display: inline-block;
    &--h1{
        font-size: 52px; 
        font-weight: bold;
        text-shadow: 0px 4px 12px rgba(31, 21, 91, 0.2);
        text-transform: uppercase;
        @include media ("<phone"){
            font-size: 36px;
        }
    }
    &--h3{
        font-size: 24px;
        font-weight: 400;
        letter-spacing: 1px;
        line-height: 1.2;
        width: 70%;
        margin-left: 15%;
        @include media ("<phone"){
            font-size: 20px;
            width: 100%;
            margin-left: 0;
        }
    }
}

.section{
    width: 100%;
    &--1{
        margin-top: -20px;
    }
    &--2{
        padding-top: 230px;
        .gradient{
            height: calc(100% + 40px);
        }
        .section__main-sub{
            padding-top: 12px;
            padding-bottom: 1px;
        }
        .section__title{
            padding-top: 10px;
        }
        @include media ("<phone"){
            padding-top: 10px;
        }
    }
    &--3{
        padding-top: 226px;
        padding-bottom: 120px;
    }
    &--4{
        padding-bottom: 180px;
        .gradient{
            height: calc(100% + 40px);
        }
        .section__main-sub{
            padding-top: 15px;
            padding-bottom: 0px;
            background-color: #1F155B;
        }
    }
    &--5{
        padding-top: 140px;
        padding-bottom: 148px;
    }
    &--6{
        .gradient{
            height: calc(100% + 20px);
        }
        .section__main-sub{
            max-width: 1160px;
            padding-top: 40px;
            padding-bottom: 100px;
            margin: 0 auto;
            border-radius: 48px;
            box-shadow: 0px 7px 12px 0px rgba(0,0,0,0.1);
            @include media ("<=tablet"){
                max-width: 980px;
            }
            @include media ("<=smallTablet"){
                border-radius: 0px;
                margin: 0;
                max-width: 100%;
             }
        }
        .section__title{
            font-size: 48px;
            margin-top: 30px;
        }
    }
}
.content_width{
    max-width: 1080px;
    width: 100%;
    margin: 0 auto;
    z-index: 4;
    position: relative;
    &--big{
        max-width: 1161px;
        padding: 1px;
    }
    @include media ("<=tablet"){
        max-width: 920px;
        &--big{
            max-width: 980px;
        }
    }
    @include media ("<=smallTablet"){
        width: calc(100% - 80px);
        max-width: 728px;
    }
    @include media ("<phone"){
        width: calc(100% - 40px);
        max-width: 380px;
    }
}
.section__main-sub{
    padding-top: 100px;
    padding-bottom: 110px;
    position: relative;
    margin-top: 20px;
    background-color: #4730D2;
}
.gradient{
    position: absolute;
    z-index: 0;
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
    top: -20px;
    left: 0;
    right: 0;
}
.gradient__img{
    position: absolute;
    top: 0%;
    left: 0;
    right: 0;
    width: 100%;
    height: auto;
    display: block;
    min-height: 400px;
    &--1{
        transform: translate(0, -50%);
    }
    &--2{
        right: -50%;
        left: auto;
        transform: translate(0, -50%);
    }
    &--3{
        bottom: 0;
        top: auto;
        left: -30%;
        right: auto;
        transform: translate(0, 50%);
    }
    &--4{
        top: auto;
        bottom: 0%;
        transform: translate(0, 0%);
    }
    &--5{
        top: auto;
        bottom: 0;
        transform: translate(0, 60%);
    }
}
.section__title{
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    text-align: center;
    color: #fff;
    font-size: 96px;
    line-height: 1.1;
    font-weight: bold;
    max-width: 956px;
    width: 100%;
    margin: 0 auto;
    &--blue{
        color: #1F155B;
    }
    @include media ("<=smallTablet"){
        font-size: 68px;
    }
    @include media ("<phone"){
        font-size: 46px;
    }
}
.section__sub-title{
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    text-align: center;
    color: #fff;
    font-size: 24px;
    line-height: 1.25;
    font-weight: 400;
    max-width: 400px;
    width: 100%;
    margin: 0 auto;
    margin-top: 22px;
    &--grey{
        color: #6F6C82;
        max-width: 640px;
    }
    
}
.section__pre-title{
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    text-align: center;
    color: rgba(255, 255, 255, 0.48);
    font-size: 16px;
    line-height: 1.2;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin: 0;
    padding-top: 38px;
    @include media ("<=smallTablet"){
        max-width: 220px;
        margin: 0 auto;
    }
}
.sub__list{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 100px;
    @include media ("<=smallTablet"){
        flex-direction: column;
    }
}
.sub__list-item{
    @include media ("<=smallTablet"){
        margin-bottom: 50px;
    }
}
.sub__list-icon{
    position: relative;
    height: 180px;
    margin: 0 auto;
    &--1{
        width: 208px;
    }
    &--2{
        width: 142px;
    }
    &--3{
        width: 136px;
    }
}
.sub__list-icon--img{
    position: absolute;
    bottom: 0;
    left: 0px;
    right: 0px;
    &--1{
        width: 192px;
        height: 172px;
        left: 18px;
        @include media ("<=smallTablet"){
            left: 0;
        }
    }
    &--2{
        bottom: 22px;
        left: 57px;
        width: 192px;
        height: 172px;
        @include media ("<=smallTablet"){
            left: 40px;
        }
    }
    &--3{
        width: 165px;
        height: 165px;
        @include media ("<=smallTablet"){
            width: 185px;
            left: -30px;
            height: auto;
        }
    }
    &--4{
        bottom: 25px;
        left: 22px;
        width: 165px;
        height: 165px;
        @include media ("<=smallTablet"){
            width: 185px;
            left: 12px;
            height: auto;
        }
    }
    &--5{
        left: -5px;
        width: 160px;
        height: 160px;
        @include media ("<=smallTablet"){
            left: -35px;
            width: 185px;
            height: auto;
        }
    }
    &--6{
        bottom: 25px;
        left: 20px;
        width: 160px;
        height: 160px;
        @include media ("<=smallTablet"){
            left: 25px;
            width: 185px;
            height: auto;
            left: 10px;
        }
    }
}
.sub__list-title{
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    text-align: center;
    color: #fff;
    font-size: 32px;
    line-height: 1.2;
    font-weight: bold;
    margin: 5px 0;
    @include media ("<=smallTablet"){
        font-size: 32px;
    }
}
.sub__list-text{
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    text-align: center;
    color:rgba(255, 255, 255, 0.48);
    font-size: 24px;
    line-height: 1.3;
    font-weight: 400;
    max-width: 270px;
    margin: 0 auto;
    display: block;
    @include media ("<=smallTablet"){
        max-width: 100%;
    }
}
.demo-btn{
    
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    text-align: center;
    color: #4730D2;
    font-size: 36px;
    line-height: 1;
    font-weight: bold;
    margin: 0 auto;
    display: block;
    margin-top: 105px;

    &--undefined{
        background-color: #fff;
        color: inherit;
        width: 360px;
        border-radius: 16px;
        box-shadow: 0px 16px 36px rgba(31, 21, 91, 0.36);
        text-decoration: none;
        cursor: pointer;
        display: block;
        padding: 21px 0px 19px 0px;
        margin: 0 auto;
        @include media ("<=smallTablet"){
            width: 90%;
            max-width: 380px;
        }
        @include media ("<phone"){
            font-size: 24px;
        }
    }
    &--defined{
        background-color: #15B743;
        color: #fff;
        width: 360px;
        border-radius: 16px;
        box-shadow: 0px 16px 36px rgba(31, 21, 91, 0.36);
        text-decoration: none;
        display: block;
        padding: 21px 0px 19px 0px;
        margin: 0 auto;
        @include media ("<=smallTablet"){
            width: 90%;
            max-width: 380px;
        }
        @include media ("<phone"){
            font-size: 24px;
        }
    }
    @include media ("<=smallTablet"){
        margin-top: 50px;
    }
}
.section__sub{
    padding-top: 140px;
    padding-bottom: 20px;
}
.two-column{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 20px 20px 0px 20px;
    &--reflected{
        .two-column__img{
            order: 1;
        }
        p{
            text-align: right;
        }
        .two-column__info{
            padding-left: 0px;
            padding-right: 45px;
            order: 2;
        }
        .index{
            left: auto;
            right: 0;
        }
        @include media ("<=smallTablet"){
            p{
                text-align: left;
            }
            .two-column__info{
                padding-right: 0px;
            }
            .index{
                left: 0;
                right: auto;
            }
        }
    }
    @include media ("<=smallTablet"){
        flex-direction: column;
        margin: 20px 0px 0px 0px;
    }
}
.two-column__info{
    padding-left: 45px;
    max-width: 505px;
    position: relative;
    @include media ("<=smallTablet"){
        padding-left: 0;
        padding-top: 25px;
        order: 2;
        max-width: 100%;
        margin-top: 20px;
        margin-bottom: 30px;
    }
}
.two-column__info-title{
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    text-align: left;
    color: #1F155B;
    font-size: 36px;
    line-height: 1.2;
    font-weight: bold;
    margin: 0;
    @include media ("<=smallTablet"){
        font-size: 32px;
    }
}
.two-column__info-text{
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    text-align: left;
    color: #6F6C82;
    font-size: 24px;
    line-height: 1.3;
    font-weight: 400;
    margin: 0;
    margin-top: 15px;
}
.two-column__img{

    &--1{
        width: 348px;
        margin-right: 70px;
        margin-top: 10px;
    }
    &--2{
        width: 343px;
        margin-left: 65px;
    }
    &--3{
        width: 501px;
        margin-right: -50px;
    }
    &--4{
        width: 405px;
        margin-right: 25px;
        margin-top: 65px;
    }
    &--5{
        width: 460px;
        margin-left: 35px;
    }
    &--6{
        width: 429px;
        margin-right: 11px;
        margin-top: 82px;
    }
    &--7{
        width: 405px;
        margin-right: 10px;
        margin-top: 53px;
    }
    &--8{
        width: 465px;
        margin-left: 20px;
        margin-top: 25px;
    }
    &--9{
        width: 480px;
        margin-right: -11px;
        margin-top: 17px;
    }
    img{
        width: 100%;
        height: auto;
    }
    @include media ("<=smallTablet"){
        width: 100%;
        max-width: 336px;
        margin-left: 0;
        margin-right: 0;
        order: 1;
    }
}
.index{
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    text-align: left;
    font-size: 12px;
    line-height: 1.2;
    font-weight: bold;
    position: absolute;
    left: 0;
    top: 20px;
    &--purple{
        color: #6D59E4;
    }
    &--green{
        color: #47CE7D;
    }
    &--blue{
        color: #7BAAF1;
    }
    @include media ("<=smallTablet"){
        top: 0;
    }
}
.sub-nav{
    position: fixed;
    bottom: 30px;
    right: 0;
    left: 0;
    width: 236px;
    height: 100px;
    border-radius: 60px;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 9px 32px;
    margin: 0 auto;
    z-index: 10;
    box-shadow: 0px 4px 6px 0px rgba(0,0,0,0.25);

    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;

    &--active {
        opacity: 1;
        pointer-events: all;
        transition: opacity 0.3s ease;
    }
}

.sub-nav__icon-img-1{
    width: 38px;
    height: auto;
    position: absolute;
    top: 22px;
    left: 6px;
}
.sub-nav__icon-img-2{
    width: 38px;
    height: auto;
    position: absolute;
    top: 22px;
    left: 6px;
    opacity: 0;
}
.sub-nav__icon-img-3{
    width: 42px;
    height: 21px;
    position: absolute;
    top: -3px;
    left: -9px;
    right: 0;
    opacity: 0;
    display: block;
    margin: 0 auto;
}
.sub-nav__icon-img-4{
    width: 42px;
    height: 21px;
    position: absolute;
    bottom: -3px;
    left: -9px;
    right: 0;
    opacity: 0;
    display: block;
    margin: 0 auto;
}
.sub-nav__icon{
    width: 68px;
    height: 100%;
    position: relative;
    &--2{
        .sub-nav__icon-img-1{
            width: 38px;
            top: 21px;
            left: 12px;
        }
        .sub-nav__icon-img-2{
            width: 38px;
            top: 21px;
            left: 12px;
        }
        .sub-nav__icon-img-3{
            left: 2px;
        }
        .sub-nav__icon-img-4{
            left: 2px;
        }
    }
    &--3{
        .sub-nav__icon-img-1{
            width: 36px;
            top: 22px;
            left: 19px;
        }
        .sub-nav__icon-img-2{
            width: 36px;
            top: 22px;
            left: 19px;
        }
        .sub-nav__icon-img-3{
            left: 15px;
        }
        .sub-nav__icon-img-4{
            left: 15px;
        }
    }
    &.active{
        .sub-nav__icon-img-2{
           opacity: 1;
        }
        .sub-nav__icon-img-3{
            opacity: 1;
        }
        .sub-nav__icon-img-4{
            opacity: 1;
        }
    }
}
.overflow{
    width: 100%;
    @include media ("<=smallTablet"){
        overflow-x: hidden;
        overflow-y: visible;
        position: absolute;
        height: 600px;
        top: -200px;
        left: 0;
    }
}

.table{
    width: 100%;
    display: block;
    margin: 0 auto;
    margin-top: 60px;
    margin-bottom: -120px;
    max-width: 1161px;
    z-index: 4;
    position: relative;
    @include media ("<=tablet"){
        max-width: 980px;
    }
    @include media ("<=smallTablet"){
       max-width: 100%;
       width: 980px;
    }
}
.table-1{
    width: 100%;
    background-color: #fff;
    border-radius: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 60px;
    @include media ("<=smallTablet"){
      width: calc(100% - 40px);
      padding: 20px 20px 15px 20px;
      flex-direction: column;
      align-items: flex-start;
      max-width: 350px;
      margin-left: 20px;
    }
}

.table-1__left{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.table-1__text{
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    color: #1F155B;
}
.table-1__btns{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    @include media ("<=smallTablet"){
       flex-direction: column;
       position: relative;
        width: 235px;
        height: 48px;
        z-index: 3;
        margin-left: 10px;
        &.table-1__btns--open{
            .table-1__btn{
                position: relative;
                display: block;
            }
        }
        &:after{
            content: '';
            position: absolute;
            top: 40%;
            right: 20px;
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 10px 6px 0 6px;
            border-color:  #4730D2 transparent transparent transparent;
        }
    }
    @include media ("<smallPhone"){
        width: 200px;
    }
}
.table-1__btn{
    background: #FFFFFF;
    border: 1px solid rgba(31, 21, 91, 0.04);
    box-shadow: 0px 2px 6px rgba(71, 48, 210, 0.16), inset 0px -2px 4px rgba(71, 48, 210, 0.08);
    border-radius: 12px;
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #4730D2;
    padding: 13px 20px 11px 20px;
    margin-left: 20px;
    &.table-1__btn--active{
        background-color: #4730D2;
        color: #fff;
    }
    @include media ("<=smallTablet"){
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        display: none;
        margin: 0;
        &.table-1__btn--active{
            display: block;
            background-color: #fff;
            color: #4730D2;
        }
     }
     @include media ("<smallPhone"){
       font-size: 17px;
    }
     
}

.table-1__descr{
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    padding-left: 20px;
    position: relative;
    margin-left: 20px;
    &:before{
        content: '';
        width: 8px;
        height: 8px;
        position: absolute;
        top: 4px;
        left: 0;
        display: block;
        border-radius: 50%;
    }
    &--green{
        color: #15B743;
        &:before{
            border: 4px solid #15B743;
        }
    }
    &--blue{
        color: #1D7EEF;
        &:before{
            border: 4px solid #1D7EEF;
        }
    }
    @include media ("<=smallTablet"){
        margin-bottom: 0;
        font-size: 19px;
     }
     @include media ("<smallPhone"){
        font-size: 17px;
        margin-top: 5px;
     }
}
.table-1__right{
    display: flex;
    flex-direction: row;
    @include media ("<=smallTablet"){
        margin-left: 20px;
    }
    @include media ("<smallPhone"){
        flex-direction: column;
     }
}
.table-2__wrapp{
    width: 100%;
    // box-shadow: 0px 20px 40px rgba(31, 21, 91, 0.08);
    // border-radius: 24px;
    @include media ("<=smallTablet"){
        overflow-x: scroll;
    }
}
.table-2__margin{
    width: 100%;
    border-radius: 24px;
    @include media ("<=smallTablet"){
        width: 980px;
        padding: 20px 20px;
        margin-bottom: 30px;
    }
}
.table-2{
    width: 100%;
    background-color: #fff;
    border-radius: 24px;
    padding: 20px 60px;
    margin-top: 24px;
    box-shadow: 0px 20px 40px rgba(31, 21, 91, 0.08);
    @include media ("<=smallTablet"){
    //    width: 980px;
       padding: 20px 20px;
    }
}
.table-2__head{
    display: flex;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    position: relative;
    &:before{
        content: '';
        width: 71.4%;
        height: 4px;
        position: absolute;
        bottom: 30px;
        left: 14.28%;
        display: block;
        background: linear-gradient(270deg, #4730D2 0%, #AEB8BF 100%);
        border-radius: 4px;
    }
}
.table-2__head-icon{
    width: 14.28%;
    img{
        width: 100px;
        height: 100px;
        display: block; 
        margin: 0 auto;
    }
}
.table-2__head-text{
    width: 14.28%;
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    padding-bottom: 25px;
    position: relative;
    &--1{
        color: #A7AEC1;
        &:after{
           background-color: #A7AEC1;
        }
    }
    &--2{
        color: #9191C5;
        &:after{
            background-color: #9191C5;
         }
    }
    &--3{
        color: #7B75C9;
        &:after{
            background-color: #7B75C9;
         }
    }
    &--4{
        color: #6558CD;
        &:after{
            background-color: #6558CD;
         }
    }
    &--5{
        color: #503CD1;
        &:after{
            background-color:#503CD1;
         }
    }
    &:before{
        content: '';
        width: 16px;
        height: 16px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: #FFFFFF;
        border: 1px solid rgba(31, 21, 91, 0.1);
        box-shadow: 0px 2px 6px rgba(71, 48, 210, 0.16), inset 0px -2px 4px rgba(71, 48, 210, 0.1);
        display: block;
        border-radius: 50%;
        margin: 0 auto;
    }
    &:after{
        content: '';
        width: 8px;
        height: 8px;
        position: absolute;
        bottom: 5px;
        left: 0;
        right: 0;
        display: block;
        border-radius: 50%;
        margin: 0 auto;
    }
}
.table-2__line{
    width: 100%;
    background: #F7F9FB;
    border-radius: 24px;
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
}
.table-2__text{
    width: 14.28%;
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    color: #1F155B;
    text-align: left;
    padding-left: 38px;
}
.table-2__check{
    width: 14.28%;
    opacity: 0;
    transition: opacity 0.1s ease;
    img{
        width: 34px;
        height: 34px;
        margin: 0 auto;
        display: block;
    }
    &.active {
        opacity: 1;
    }
}
.fig-1{
    position: absolute;
    top: -78px;
    left: 8%;
    width: 292px;
    height: 260px;
    z-index: 2;
    @include media ("<=tablet"){
        left: 2%;
        width: 242px;
        height: 210px;
    }
    @include media ("<=smallTablet"){
        left: -3%;
        width: 242px;
        height: 210px;
        top: 150px;
    }
    @include media ("<phone"){
        left: -70px;
        width: 145px;
        height: 130px;
        top: 150px;
    }
}
.fig-2{
    position: absolute;
    top: 120px;
    left: 39%;
    width: 132px;
    height: 117px;
    z-index: 2;
    @include media ("<=tablet"){
        width: 112px;
        height: 97px;
        top: 140px;
    }
    @include media ("<=smallTablet"){
        width: 112px;
        height: 97px;
        top: 320px;
    }
    @include media ("<phone"){
        width: 66px;
        height: 58px;
        top: 320px;
    }
}
.fig-3{
    position: absolute;
    top: -65px;
    right: 8%;
    width: 405px;
    height: 360px;
    z-index: 2;
    @include media ("<=tablet"){
        right: 1%;
        width: 325px;
        height: 280px;
    }
    @include media ("<=smallTablet"){
        right: -5%;
        width: 325px;
        height: 280px;
        top: 135px;
    }
    @include media ("<phone"){
        right: -90px;
        top: 230px;
        width: 201px;
        height: 180px;
    }
}
.fig-4{
    position: absolute;
    top: 133px;
    left: 13%;
    width: 113px;
    height: 94px;
    z-index: 2;
    @include media ("<=tablet"){
        left: 5%;
        top: 65px;
    }
    @include media ("<phone"){
        top: 13px;
        left: 7%;
        width: 73px;
        height: auto;
    }
}
.fig-5{
    position: absolute;
    top: 265px;
    right: 14%;
    width: 60px;
    height: 84px;
    z-index: 2;
    @include media ("<=tablet"){
        right: 7%;
        top: 220px;
    }
    @include media ("<phone"){
        top: 145px;
        right: 5%;
        width: 35px;
        height: auto;
    }
}
.fig-6{
    position: absolute;
    top: -30px;
    right: 8%;
    width: 193px;
    height: 100px;
    z-index: 2;
    @include media ("<phone"){
        top: -20px;
        right: 3%;
        width: 103px;
        height: auto;
    }
}
.fig-7{
    position: absolute;
    top: 140px;
    left: 12%;
    width: 137px;
    height: 124px;
    z-index: 2;
    @include media ("<=tablet"){
        left: 6%;
    }
    @include media ("<phone"){
        top: 30px;
        left: 1%;
        width: 97px;
        height: 84px;
    }
}
.three-column{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 160px;
    &--line-2{
        margin-top: 0;
        .three-column__item{
            &--2{
                &:before{
                    display: none;
                }
                &:after{
                    display: none;
                }
            }
            @include media ("<=smallTablet"){
                margin-bottom: 0;
            }
        }

    }
    @include media ("<=tablet"){
        margin-top: 70px;
    }
    @include media ("<phone"){
        flex-direction: column;
    }
}
.three-column__item{
    width: 31.8%;
    position: relative;
    &--1{
        .three-column__text{
            color: #F45F83;
            @include media ("<=smallTablet"){
                margin-left: 0;
            }
        }
    }
    &--2{
        &:before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 1px;
            height: 360px;
            background-color: #E4E2EE;
            display: block;
            margin: 0 auto;
            @include media ("<=smallTablet"){
                left: -7%;
            }
        }
        &:after{
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 1px;
            height: 360px;
            background-color: #E4E2EE;
            display: block;
            margin: 0 auto;
            @include media ("<=smallTablet"){
                right: -7%;
            }
        }
        .three-column__text{
            color: #A983FA;
            @include media ("<=smallTablet"){
                margin-left: 10%;
            }
            @include media ("<phone"){
                margin-left: 0;
            }
        }
    }
    &--3{
        .three-column__text{
            color: #7BAAF1;
            @include media ("<=smallTablet"){
                margin-right: 0;
                margin-left: auto;
            }
        }
    }
    @include media ("<phone"){
        width: 100%;
        margin-bottom: 100px;
        &--2{
            &:before{
                display: none;
            }
            &:after{
                display: none;
            }
        }
        &--3{
            margin-bottom: 0;
        }
    }
}
.three-column__text{
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    text-align: center;
    font-size: 24px;
    line-height: 1.2;
    font-weight: bold;
    min-height: 120px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    margin: 0;
    span{
        width: 100%;
    }
    &:after{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 200px;
        height: 1px;
        background-color: #E4E2EE;
        display: block;
        margin: 0 auto;
    }
    &:first-child{
        &:after{
            display: none;
            
        }
    }
    @include media ("<=smallTablet"){
        font-size: 21px;
        max-width: 80%;
        &:after{
            width: 170px;
        }
    }
    @include media ("<phone"){
        min-height: 95px;
        font-size: 24px;
        max-width: 100%;
    }
}
.three-column__profit{
    border: 1px solid rgba(31, 21, 91, 0.04);
    box-shadow: 0px 2px 6px rgba(29, 207, 101, 0.16), inset 0px -2px 4px rgba(29, 207, 101, 0.16);
    border-radius: 24px;
    width: 100%;
    height: 180px;
    padding: 37px 20px 30px 20px;
    margin-top: 55px;
    @include media ("<phone"){
       max-width: 380px;
       margin: 0 auto;
       margin-top: 30px;
       display: flex;
       flex-direction: column;
       align-items: center;
       justify-content: space-around;
    }
}
.three-column__profit-num{
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    text-align: center;
    font-size: 36px;
    line-height: 1.2;
    font-weight: bold;
    color: #1DCF65;
    margin: 0;
    @include media ("<=smallTablet"){
       font-size: 34px;
       margin-bottom: 10px;
    }
}
.three-column__profit-text{
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    text-align: center;
    font-size: 24px;
    line-height: 1.2;
    font-weight: 400;
    color: #1F155B;
    margin: 0;
    margin-top: 10px;
    @include media ("<=tablet"){
        font-size: 22px;
        margin-top: 0;
    }
}
.title-img{
    width: 100%;
    max-width: 956px;
    display: block;
    height: auto;
    margin: 0 auto;
    margin-top: 56px;
    @include media ("<=smallTablet"){
        margin-top: 20px;
    }
}
.infograph{
    position: relative;
    width: 100%;
    margin: 0px auto 70px 40px;
    max-width: 1110px;
    @include media ("<=tablet"){
        max-width: 920px;
    }
    @include media ("<=smallTablet"){
        max-width: 728px;
        margin: 0px auto 70px auto;
    }
    @include media ("<=smallTablet"){
        margin: 0px auto 41px auto;
    }
}
.infograph__img-1{
    width: 100%;
    height: auto;
}
.infograph__img-2{
    position: absolute;
    left: 0px;
    right: 0;
    top: 0;
    width: 100%;
    height: auto;
}
.clients{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 40px;
    @include media ("<=smallTablet"){
        justify-content: space-around;
        max-width: 680px;
        margin: 0 auto; 
        margin-top: 40px;
     }
     @include media ("<phone"){
        max-width: 380px;
     }
}
.clients__img{
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 80px;
    &:first-child{
        margin-left: 0px;
    }
    &:nth-child(6n){
        margin-left: 0px;
    }
    @include media ("<=tablet"){
        &:nth-child(6n){
            margin-left: 80px;
        }
        &:nth-child(5n){
            margin-left: 0px;
        }
    }
    @include media ("<=smallTablet"){
        width: 50%;
        margin-left: 0;
        &:nth-child(6n){
            margin-left: 0px;
        }
    }

    &--1{
        width: 116px;
        height: 64px;
    }
    &--2{
        width: 114px;
        height: 40px;
    }
    &--3{
        width: 160px;
        height: 49px;
    }
    &--4{
        width: 142px;
        height: 60px;
        @include media ("<phone"){
            width: 132px;
        }
    }
    &--5{
        width: 184px;
        height: 38px;
        @include media ("<=smallTablet"){
           width: 154px;
        }
    }
    &--6{
        width: 188px;
        height: 60px;
        @include media ("<phone"){
            width: 164px;
        }
    }
    &--7{
        width: 240px;
        height: 33px;
    }
}
.form{
    width: 100%;
    max-width: 960px;
    margin: 0 auto;
    z-index: 4;
    position: relative;
    margin-top: 66px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @include media ("<=tablet"){
        max-width: 860px;
    }
    @include media ("<=smallTablet"){
        flex-direction: column;
        max-width: 728px;
    }
    @include media ("<phone"){
        max-width: 380px;
    }
}
.form__input{
    display: inline-block;
    height: 64px;
    width: calc((100% - 240px)/2);
    border-radius: 12px;
    border: 1px solid #FFFFFF;
    background-color: rgba(#fff, 0);
    padding: 21px 20px 19px 20px;
    outline: none !important;
    &::placeholder{
        font-family: 'Kumbh Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
        color: #fff;
    }
    @include media ("<=smallTablet"){
       width: 100%;
       margin-bottom: 25px;
    }
}
.form__submit{
    width: 160px;
    height: 64px;
    background-color: #fff;
    border-radius: 12px;
    padding: 21px 20px 19px 20px;
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #4730D2;
    border: 1px solid #FFFFFF;
    @include media ("<=smallTablet"){
        width: 100%;
     }
}
.footer{
    width: 100%;
    padding-top: 94px;
    padding-bottom: 199px;
    @include media ("<phone"){
        padding-top: 60px;
        padding-bottom: 60px;
    }
}
.footer_col{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @include media ("<=smallTablet"){ 
        max-width: 728px;
        margin: 0 auto;
    }
    @include media ("<phone"){
        flex-direction: column;
        max-width: 380px;
    }
}
.footer__text{
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
    color: #B2AFBF;
    margin: 0;

    @include media ("<=smallTablet"){
        font-size: 18px;
    }
    @include media ("<phone"){
        text-align: center;
        &--made{
            display: block;
            width: 100%;
            margin-top: 10px;
        }
    }
}
.footer__col-left{
    @include media ("<=phone"){
        order: 2;
    }
}
.footer__col-right{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    max-width: 310px;
    width: 100%;
    @include media ("<=smallTablet"){
        max-width: 250px;
    }
    @include media ("<=phone"){
        margin-bottom: 30px;
        flex-direction: column;
        max-width: 100%;
        text-align: center;
    }
}
.footer__nav{
    font-family: 'Kumbh Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 39px;
    color: #1F155B;
    text-decoration: none;
    @include media ("<=smallTablet"){
        font-size: 19px;
    }
}
.sociallinks{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 10px;
    @include media ("<=phone"){
        justify-content: center;
        margin-right: 0;
        margin-bottom: 20px;
    }
}
.sociallinks__item{
    margin-right: 10px;
}

.policy-text {
    font-family: 'Roboto',Arial,sans-serif;
    font-weight: 300;
    color: #000;
    line-height: 1.5;

    width: 90%;
    max-width: 760px;
    margin: 0 auto;
    margin-top: 80px;
    margin-bottom: 80px;
    @include media ("<=phone"){
        font-size: 16px;
        line-height: 1.45;
    }
    a{
        color: #ff8562;
        text-decoration: none;
    }
}

.article {
    margin: 2.5em auto 0;
    font-family: 'SFProText-Regular',sans-serif;
    font-weight: 400;
    color: rgba(44,32,48,.6);
    font-size: 24px;
    width: 80%;
    max-width: 950px;
    min-width: 320px;
    margin: 0 auto;
    padding: 0 4rem;
    background-color: #fff;
}

.article__content {
    max-width: 95rem;
    padding: 6rem 0 6rem 0;
    .input {
        margin-bottom: 1em;
        width: 100%;
        input {
            display: block;
            width: 100%;
            font-size: 1em;
            font-family: 'SFProDisplay-Medium',sans-serif;
            color: inherit;
            -webkit-appearance: none;
            background-color: #fdfdfd;
            outline: none;
            margin: 0;
            padding: 0;
            border: 0;
        }
    }
    .input__label {
        display: block;
        margin-bottom: .5em;
        line-height: 1;
    }
    .input__block {
        position: relative;
        overflow: hidden;
        border-radius: 4px;
        background-color: #fdfdfd;
        border: solid 1px #e3e3e3;
        padding: .625em .875em;
    }
    .input--error .input__error_text {
        display: block;
    }
    .input__error_text {
        display: none;
        font-size: 1.4rem;
        font-size: .875em;
        margin-top: .5rem;
        color: #e66666;
    }
    textarea {
        width: 100%;
        height: 300px;
        resize: none;
        border: none;
        color: inherit;
        font-family: 'SFProDisplay-Medium',sans-serif;
        font-size: 1em;
        background-color: #fdfdfd;
        overflow: auto;
        outline: none;
        -webkit-appearance: none;
    }
    .btn {
        text-align: center;
        padding: 1em;
        border-radius: 4px;
        background-color: #5260f9;
        font-size: 1em;
        font-family: 'SFProDisplay-Medium',sans-serif;
        font-weight: 400;
        color: #fff;
        line-height: 1;
        text-decoration: none;
        outline: none;
        margin: 0;
        border: 0;
        cursor: pointer;
    }
    .btn__label{
        display: inline-block;
    }
}   